import React from "react";
// import { graphql } from "gatsby";
import Root from "../Root";
import SEO from "../seo";
import AllAlgorithms from "./AllAlgorithms";

// export const query = graphql`
//   query {
//     allMongodbWheretomineAlgorithms {
//       nodes {
//         name
//         numberOfSupportedCoins
//         slug
//       }
//     }
//   }
// `;

const AlgorithmsPage = ({ pageContext }) => {
  const { algorithms } = pageContext;

  return (
    <Root>
      <SEO title="Coins" />
      <AllAlgorithms algorithms={algorithms} />
    </Root>
  );
};

export default AlgorithmsPage;
