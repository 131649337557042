import React from "react";
import { Link } from "gatsby-theme-material-ui";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import Profile from "../Constant/Profile";
import GradientLine from "../Constant/GradientLine";
import NavajoAboveTable from "../Navajo/NavajoAboveTable";
import BreadcrumsControls from "../Constant/BreadcrumsControls";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
  },
  breadcrums: {
    margin: "40px auto 40px",
  },
  header: {
    fontSize: "34px",
    lineHeight: "1.3",
    color: theme.palette.text.primary,
    fontWeight: "700",
    marginBottom: 10,
  },
  description: {
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: "1.6",
    color: theme.palette.text.secondary,
  },
  tableRow: {
    display: "flex",
    borderBottom: "unset",
  },
  tableCell: {
    padding: "12px 0px 12px",
    display: "flex",
    alignItems: "center",
    borderBottom: "unset",
  },
  firstTableCell: {
    width: "75%",
  },
  lastTableCell: {
    width: "25%",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    transition: "color .1s ease-out",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  linkTitle: {
    fontSize: "1rem",
    lineHeight: "1.2rem",
    fontWeight: "600",
    letterSpacing: ".04em",
    textTransform: "none",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  linkSubtitle: {
    fontWeight: "500",
    fontSize: "0.75rem",
    letterSpacing: ".04em",
    textTransform: "none",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
}));

const AllAlgorithms = props => {
  const { algorithms } = props;
  const classes = useStyles(props);

  const breadcrums = [
    { to: "/", page: "Home" },
    { to: "/algorithms", page: "Algorithms" },
  ];

  return (
    <div className={classes.root}>
      <BreadcrumsControls breadcrums={breadcrums} currentPage={`All`} />

      <section>
        <Profile title={"All Algorithms"} additionalTitle={` `} />
      </section>

      <section>
        <NavajoAboveTable>
          <div style={{ display: "flex" }}></div>
        </NavajoAboveTable>

        <GradientLine />

        <Container maxWidth="lg">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell className={clsx(classes.tableCell, classes.firstTableCell)} align="left" sortDirection={false}>
                    <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
                      Algorithm
                    </Typography>
                  </TableCell>
                  {/* <TableCell className={clsx(classes.tableCell, classes.normalTableCell)} align="left" sortDirection={false}>
                    <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
                      Pools
                    </Typography>
                  </TableCell> */}
                  <TableCell className={clsx(classes.tableCell, classes.lastTableCell)} align="right" sortDirection={false}>
                    <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
                      Coins
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ padding: 0, borderBottom: "unset" }}>
                    <GradientLine />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {algorithms.map((algorithm, index) => {
                //  const numberOfSubPools = algorithm.supportedCoins.reduce((initial, coin) => {
                //    return initial + coin.numberOfSubPools;
                //  }, 0);
                  return (
                    <TableRow className={classes.tableRow} key={index} tabIndex={-1}>
                      <TableCell className={clsx(classes.tableCell, classes.firstTableCell)} component="th" scope="row" align="left">
                        <Link className={classes.link} to={`/algorithms/${algorithm.slug}`}>
                          <Typography className={classes.linkTitle} variant="subtitle1">
                            {index + 1}. {algorithm.name}
                          </Typography>
                        </Link>
                      </TableCell>
                      {/* <TableCell className={clsx(classes.tableCell, classes.normalTableCell)} component="th" scope="row" align="left">
                        <div className={classes.link}>
                          <Link className={classes.link} to={`/algorithms/${algorithm.slug}`}>
                            <Typography className={classes.linkTitle} variant="subtitle1">
                              {numberOfSubPools}
                            </Typography>
                          </Link>
                        </div>
                      </TableCell> */}
                      <TableCell className={clsx(classes.tableCell, classes.lastTableCell)} component="th" scope="row" align="right">
                        <Link className={classes.link} to={`/algorithms/${algorithm.slug}`}>
                          <Typography className={classes.linkTitle} variant="subtitle1">
                            {algorithm.numberOfSupportedCoins}
                          </Typography>
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>

        <GradientLine />
      </section>
    </div>
  );
};

export default AllAlgorithms;
